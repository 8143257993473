<template>
    <div class="page">
        <ProfileMenu section="change-password" />
        <div class="page-header">
            <h1>更改密碼</h1>
            <div class="required">* 必需填寫</div>
        </div>
        <div class="form-wrapper" v-if="!isSaved">
            <div class="legend">
                <ul class="group">
                    <li><label>現有密碼 (*)</label></li>
                    <li>
                        <input type="password" v-model="form.current_password" required ref="current_password" />
                        <div v-if="error === 'current_password'" class="error">請填寫現有密碼</div>
                    </li>
                </ul>
                <ul class="group">
                    <li><label>新密碼 (*)</label></li>
                    <li>
                        <input type="password" v-model="form.new_password" required ref="new_password" />
                        <div v-if="error === 'new_password'" class="error">請填寫新密碼</div>
                    </li>
                </ul>
                <ul class="group">
                    <li><label>再次輸入新密碼 (*)</label></li>
                    <li>
                        <input type="password" v-model="form.new_retype_password" required ref="new_retype_password" />
                        <div v-if="error === 'new_retype_password'" class="error">請再次輸入新密碼</div>
                    </li>
                </ul>
                <ul class="group no-input">
                    <li><label>密碼必需包含 :</label></li>
                    <li>
                        最少8個字元<br>
                        大階和細階字母<br>
                        最少一個數字
                    </li>
                </ul>
            </div>
            
            <div class="error-wrapper">
                <div class="error" v-if="errorRetype">{{ $Lang.get('retype-password-not-match') }}</div>
                <div class="error" v-if="errorPassword">{{ $Lang.get('current-password-incorrect') }}</div>
                <div class="error" v-if="errorPasswordStrength">{{ $Lang.get('weak-password-strength') }}</div>
                <div class="error" v-if="submit_error">更改密碼失敗，請確認您的現有密碼正確。</div>
            </div>

            <div class="control" v-if="!submitting">
                <input type="submit" value="遞交" @click="submit" />
            </div>
            <div class="loader" v-if="submitting && !submit_error">
                <span class="icon-loading"></span><span>資料遞交中...</span>
            </div>
        </div>

        <div class="successfully-page" v-if="isSaved">
            <div class="message">
                您的密碼已更改，將於下一次登入時生效。
            </div>
            <div class="control">
                <input type="button" value="返回" @click="back" />
            </div>
        </div>
    </div>
</template>
<script>
import ProfileMenu from './ProfileMenu'
import Fn from './Fn'
export default {
    mixins: [ Fn ],
    components: { ProfileMenu },
    data() {
        return {
            isSaved: false,
            form: {},
            error: '',
            submit_error: false,
            submitting: false,
            errorRetype: false,
            errorPassword: false,
            errorPasswordStrength: false
        }
    },
    methods: {
        back() {
            this.form = {}
            this.isSaved = false
        },
        checkStrength (value) {
            this.new_password_status = this.checkPasswordStrength(value)
        },
        checkRetypeStrength (value) {
            this.new_password_retype_status = this.checkPasswordStrength(value)
        },
        submit () {
            this.error = false
            this.errorRetype = false
            this.errorPassword = false
            this.errorPasswordStrength = false
            this.submit_error = false

            if (!this.error && ( this.form.current_password == '' || this.form.current_password == undefined) ) {
                this.error = 'current_password'
                this.$refs['current_password'].focus()
            }
            if (!this.error && ( this.form.new_password == '' || this.form.new_password == undefined) ) {
                this.error = 'new_password'
                this.$refs['new_password'].focus()
            }
            if (!this.error && ( this.form.new_retype_password == '' || this.form.new_retype_password == undefined) ) {
                this.error = 'new_retype_password'
                this.$refs['new_retype_password'].focus()
            }


            if (!this.error) {
                this.errorRetype = false
                this.errorPassword = false
                if (this.form.new_password !== this.form.new_retype_password) {
                    this.errorRetype = true
                    return
                }
                
                let strength = this.checkPasswordStrength(this.form.new_password)
                if (strength === 'weak')
                {
                    this.errorPasswordStrength = true;
                } else {
                    this.submitting = true
                    const params = new FormData()
                    for (let i in this.form) {
                        params.append(i, this.form[i])
                    }
                    params.append('token', this.Auth.getToken())

                    this.$axios
                        .post(process.env.VUE_APP_API + 'change-password' + process.env.VUE_APP_SERVER_SIDE_LANG, params)
                        .then(response => {
                            let r = response.data
                            if (r.success === true) {
                                this.isSaved = true
                            } else {
                                this.submit_error = true
                            }
                            this.submitting = false
                        })
                        .catch(function () {
                            this.submit_error = true
                            this.submitting = false
                        })
                }
            }
        }
    }   
}
</script>

<style scoped lang="scss">
@import 'Page.scss';
</style>
 